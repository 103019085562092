import React from 'react';
import { graphql } from 'gatsby';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import { BlogPostHeader, BlogPostSidebar, Image, Layout, PostGrid, Section, Slice } from 'components';
import * as Slices from '../slices';
import './blog_post.scss';

const IS_BROWSER = typeof window !== 'undefined';

export const BlogPost = ({ location, data: staticData }) => {
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const data = mergePrismicPreviewData({ staticData, previewData });
  const { prismicBlogPost: blogPost, allPrismicBlogPost: recentPosts = {} } = staticData;
  const { data: pageData } = blogPost;
  const { nodes: filteredPosts = [] } = recentPosts;

  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    title,
    featured_image: heroImage,
    timestamp,
  } = pageData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.localFile && ogImage.localFile.childImageSharp.gatsbyImageData.src,
  };

  const sideBarData = {
    title: 'Book a tour',
    content: 'Something about booking a tour and dogs',
    to: '/',
    text: 'BOOK NOW',
  };

  return (
    <Layout>
      <div className="blog-post">
        <BlogPostHeader title={title} timestamp={timestamp} />
        <Section className="blog-post-image">
          <Image image={heroImage} className="blog-post-hero-image" />
        </Section>
        <div className="blog-post-body">
          <div className="blog-post-content-container">
            <div className="blog-post-slice-content">
              {sliceData.map((slice, index) => (
                <Slice key={slice.id} data={slice} sliceIndex={index} />
              ))}
            </div>
            <BlogPostSidebar filteredPosts={filteredPosts} sideBarData={sideBarData} location={location} />
          </div>
        </div>
        {/* {filteredPosts.length > 0 && (
          <PostGrid posts={filteredPosts} className="recent-posts" title="Recent Posts" viewAll />
        )} */}
      </div>
    </Layout>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostBySlug($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      id
      uid
      prismicId
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        open_graph_image {
          url
        }
        title {
          text
          html
        }
        featured_image {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        excerpt {
          text
          html
        }
        timestamp(formatString: "MMM d, YYYY")
        is_featured
        main_featured
        body {
          ...WysiwygPageFragment
        }
      }
    }
    allPrismicBlogPost(limit: 3, filter: { uid: { ne: $uid } }, sort: { order: ASC, fields: last_publication_date }) {
      nodes {
        uid
        data {
          excerpt {
            text
            html
          }
          featured_image {
            url
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          title {
            html
            text
          }
        }
      }
    }
  }
`;
